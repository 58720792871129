
<template>
  <button @click="remove" class="btn pl-4 pr-4 btn-sm btn-warning"><i class="fa fa-trash"></i> Remover</button>
</template>
<script>
import Swal from "sweetalert2";
import UsersStore from "@/modules/users/users-store";

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  watch: {
    'form.name': function (newValue) {
      this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    remove() {

      Swal.fire({
        title: "Atenção!",
        text: "Deseja mesmo remover este Usuário?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sim, mover pra lixeira!",
      }).then((result) => {
        if (result.value) {
          UsersStore.remove(this.value.id).then(() => {
            this.$emit("change", this.value)
            Swal.fire(
                "Removido!",
                "Usuário movido pra lixeira com sucesso.",
                "success"
            );
          }).catch(() => {
            Swal.fire(
                "Erro!",
                "Erro ao remover o Usuário.",
                "error"
            );
          });
        }
      });


    }
  }
}
</script>
